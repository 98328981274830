:root {
    // User
    // --bg-img: url('../img/bg-orange.svg')
    // --bg-img-kota: url('../img/big-background.png');
    --bg-img-kota: url('../img/bri-bg.png');
    --bg-img-konten: url('../img/bri-bg-user.png');
    --btn-color-primary: radial-gradient(rgb(33, 150, 243), rgb(0, 82, 156)) white;
    --btn-color-second:linear-gradient(96.38deg, #F4901E 10.27%, #DA5307 72.1%);
    --bg-scrollbar: #0466C9;
    --bg-thumb-scrollbar: #C4C4C4;
    --label-color:#0078B7;
    --color-card:#0078B7;
    --img-cabang: url('../img/bri-cabang.png');

}